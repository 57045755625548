<template>
  <Registrations
    :id="id"
    :title="$t('app.submissions', 2)"
    hide-all-versions
    :endpoint="`${endpoint}.submissions`"
    :resource-type="registrationTypes.ATTESTATION"
    @view="onView"
  />
</template>

<script>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useI18n } from "vue-i18n";
// Composables
import useTexts from "@/composables/useTexts";
import useAttestation from "@/composables/useAttestation";
// Components
import Registrations from "@/components/templates/Registrations";
// Constants
import registrationTypes from "@/constants/registrationTypes";

export default {
  components: {
    Registrations
  },
  props: {
    id: {
      type: [Number, String],
      required: true
    },
    attestation: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    // Misc
    const router = useRouter();
    const { t } = useI18n();

    // Composables
    const { endpoint } = useAttestation();
    const { getText } = useTexts();

    // Computed
    const documentTitle = computed(() => {
      // eslint-disable-next-line
      const title = `${t("app.submissions", 2)} - ${t("app.attestations", 2)} - ${t("app.teaching")}`;

      const name = getText(props.attestation?.texts, "name");
      if (name) {
        return `${name} - ${title}`;
      }

      return title;
    });

    // Methods
    const onView = item => {
      const submissionId = item?.id;

      if (!submissionId) {
        return;
      }

      router.push({
        name: "teaching-attestations-submissions-details",
        params: {
          id: props.id,
          submissionId
        }
      });
    };

    return {
      onView,
      documentTitle,
      registrationTypes,
      // useAttestation
      endpoint
    };
  }
};
</script>
